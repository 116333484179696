<template>
  <b-container fluid class="pl-0">
    <div class="d-flex">
      <div class="sidebar-fixed">
        <div class="sidebar-title">
          產品系列
        </div>
        <div class="py-1">
          <multiselect
            v-model="globalSearch"
            :allow-empty="true"
            track-by="id"
            :options="products"
            :custom-label="nameFormatter"
            @input="handleValueChange"
            @remove="handleValueRemove"
            placeholder="搜尋所有產品"
            :show-labels="false"
          >
            <template #placeholder>
              <div class="d-flex align-items-center" style="gap: 8px">
                <div><b-icon icon="search"></b-icon></div>
                <div style="font-size: 14px">
                  <span style="color: gray">搜尋所有產品</span>
                </div>
              </div>
            </template>
            <template #singleLabel="{ option }">
              <div class="d-flex align-items-center" style="gap: 8px">
                <div><b-icon icon="search"></b-icon></div>
                <div style="font-size: 14px">
                  {{ option.nameZhHk }}
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <div class="d-flex align-items-center" style="gap: 8px">
                <div v-if="!!option.imageUrl">
                  <img :src="option.imageUrl[0]" width="30" height="30" />
                </div>
                <div
                  style="
                    white-space: normal !important;
                    word-break: break-all;
                    font-size: 14px;
                  "
                >
                  {{ option.nameZhHk }}
                </div>
              </div>
            </template>
            <template #noResult>
              <div class="text-muted" style="padding: 10px;font-size: 12px">
                No results found
              </div>
            </template>
          </multiselect>
        </div>
        <!-- Scrollable List Group -->
        <div class="list-group-container" >
          <b-list-group class="rounded-0">
            <b-list-group-item
              v-for="(item, idx) in seriesOptions"
              :key="'seriesId-'+ idx + '-' + item.value"
              class="cursor-pointer"
              @click="onClickSeriesList(item)"
              :active="!!filters.seriesId && filters.seriesId === item.value"
              :href="`/product#${item.value}`"
              >
              {{ item.text }}
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <!-- Main Content -->
      <div class="content-area">
        <b-row class="mb-4">
          <b-col cols="12" xl="4" md="6" class="mb-2">
            <div class="input-group" >
              <span class="input-group-text input-group-text--addon-left">
                <b-icon icon="search"></b-icon>
              </span>
              <input
                v-model="filters.name"
                type="text"
                class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px mr-4 mr-4"
                placeholder="搜索產品名稱"
              />

          <!-- <b-form-select
            style="color: #bec0d0"
            class="form-control--h46px w-5 rounded-10px"
            name="series"
            v-model="filters.seriesName"
            :options="seriesOptions"
          ></b-form-select> -->
            </div>
          </b-col>
          <b-col cols="12" xl="2" md="6" class="mb-2">
            <router-link
              to="product/create"
              class="btn btn-secondary btn--h46px justify-content-center w-100"
            >
              新創建
            </router-link>
          </b-col>
          <b-col cols="12" xl="3" md="6" class="mb-2">
            <b-form-datepicker
              id="datepicker-start"
              v-model="filters.start"
              class="b-form--datepicker form-control--h46px"
              today-button
              reset-button
              close-button
              locale="zh"
              placeholder="2021 年 11 月 10 日"
            ></b-form-datepicker>
          </b-col>
          <b-col cols="12" xl="3" md="6" class="mb-2">
            <b-form-datepicker
              id="datepicker-end"
              v-model="filters.end"
              class="b-form--datepicker form-control--h46px"
              today-button
              reset-button
              close-button
              locale="zh"
              placeholder="2021 年 11 月 10 日"
            ></b-form-datepicker>
          </b-col>
        </b-row>

        <div class="card border-0 shadow mb-4">
          <div class="table-responsive mb-0">
          <table class="table table-hover">
            <thead class="bg-brown--light">
              <tr>
                <th class="text-center text-nowrap"></th>
            <th class="text-center text-nowrap">產品系列</th>
            <th class="text-center text-nowrap">分類數量</th>
                <th class="text-center text-nowrap">排序序號</th>
                <th class="text-center text-nowrap">產品編號</th>
                <th class="text-center text-nowrap">預覽產品相片</th>
                <th class="text-center text-nowrap">產品名稱</th>
                <th class="text-center text-nowrap">产品名称(简体版本)</th>
                <th class="text-center text-nowrap">產品描述</th>
                <th class="text-center text-nowrap">产品描述 (简体版本) </th>
                <th class="text-center text-nowrap">價格 (HKD)</th>
                <th class="text-center text-nowrap">折後價 (HKD)</th>
                <th class="text-center text-nowrap">庫存</th>
                <th class="text-center text-nowrap">創建日期</th>
                <th class="text-center text-nowrap">狀態</th>
                <th class="text-center text-nowrap">操作</th>
              </tr>
            </thead>
            <template v-if="isLoading">
              <tr v-for="n of 10" :key="n" class="animate-pulse">
                <td v-for="header of 14" :key="header">
                  <div
                    class="align-middle text-center text-nowrap py-3 rounded-pill"
                    style="background-color: rgb(229, 231, 235)"
                  >
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else-if="!paginatedData.length">
              <td
                :colspan="9"
                class="align-middle py-4 text-left text-nowrap"
              >
                沒有找到任何結果
              </td>
            </tr>
            <draggable
              v-else tag="tbody"
              v-model="draggableData"
              @end="handleDragEnd"
              :disabled='disabledDraggable'
              >
              <tr
                v-for="(item, idx) in paginatedData"
                :key="item.id + '-' + idx"
                :data-id="item.id"
                :class="{'grab': !dragging && !disabledDraggable}"
                :style="!item.active ? 'background: rgba(100, 100, 100, 0.2);' : ''"
                >
                <td class="align-middle text-center text-nowrap">
                  <div class="d-flex align-items-center"><IconSortingOrder :class="disabledDraggable ? 'opacity-25': 'opacity-100'" style="height: 18px; width:18px;" /></div>
                </td>
            <td class="align-middle text-center text-nowrap">
              {{item.seriesNameHK}}
            </td>
            <td class="align-middle text-center text-nowrap">
              {{item.sortingOrder}}
            </td>
                <td class="align-middle text-center text-nowrap">{{item.sortingOrder}}</td>
                <td class="align-middle text-center text-nowrap">{{item.posId}}</td>
                <td class="align-middle text-center">
                  <img width="75" class="img-fluid rounded-sm shadow-sm" v-if="item.imageUrl" :src="item.imageUrl[0]" />
                </td>
                <td class="align-middle text-center">{{item.nameZhHk}}</td>
                <td class="align-middle text-center">{{item.nameZhCn}}</td>
                <td class="align-middle text-center">
                  <div class="text-truncate-3" v-html="item.descriptionZhHk"></div>
                </td>
                <td class="align-middle text-center">
                  <div class="text-truncate-3" v-html="item.descriptionZhCn"></div>
                </td>
                <td class="align-middle text-center text-nowrap">{{item.price}}</td>
                <td class="align-middle text-center text-nowrap">{{!item.discountPercentage ? item.price : (item.price / 100 * ( 100 - item.discountPercentage )).toFixed(2)}}</td>
                <td class="align-middle text-center text-nowrap">{{item.inventory}}</td>
                <td class="align-middle text-center text-nowrap">{{item.createdDate | formatDate}}</td>
                <td class="align-middle text-center text-nowrap">
                  <template>
                    <div v-if="item.active == true" class="text-success">啟用</div>
                    <div v-else-if="item.active == false" class="text-danger">停用</div>
                  </template>
                </td>
                <td class="align-middle text-center text-nowrap">
                  <b-dropdown  right variant="link" boundary="viewport" toggle-class="text-white text-decoration-none" no-caret>
                    <template #button-content>
                      <b-icon icon="three-dots" font-scale="1.5" class="text-dark"></b-icon>
                    </template>

                    <b-dropdown-item :to="'/product/'+item.id+'/edit'+ ($route.hash ? $route.hash : '')">編輯</b-dropdown-item>
                    <b-dropdown-item :to="'/product/'+item.id+'/inventory'+ ($route.hash ? $route.hash : '')">存貨</b-dropdown-item>
                    <b-dropdown-item @click="showSortingModal(item)">排序順序</b-dropdown-item>
                    <b-dropdown-item :disabled="item.active == false" @click="handleDeactivate(item.id)">停用</b-dropdown-item>
                    <b-dropdown-item :disabled="item.active == true" @click="handleActivate(item.id)">啟用</b-dropdown-item>
                    <b-dropdown-item @click="handleDelete(item.id ,idx)">刪除</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    <!-- Pagination -->
    <Pagination v-if="filterProduct.length" v-model="filters.page" :list-data="filterProduct" :limit="filters.size" />
      </div>
    </div>

    <!-- Modal for sorting -->
    <b-modal id="setSortingOrder" centered no-close-on-backdrop hide-footer hide-header size="lg">
      <b-form class="p-3" @submit.prevent="submitChangeSorting(+selectedPro.sortingOrder, +selectedSortOrder)">
        <h5 class="text-brown mb-4">更改產品的排序順序</h5>
        <b-row class="mb-3" v-if="!!selectedPro">
          <b-col sm="6" lg="4">
            <div v-if="selectedPro.imageUrl.length" class="d-flex justify-content-center align-items-center" style="height: 160px; width: 100%;">
              <img :src="selectedPro.imageUrl[0]" class="img-fluid rounded-sm shadow-sm" style="object-fit: cover; width: 100%; height: 100%;" />
            </div>
          </b-col>
          <b-col sm="6" lg="4" class="align-self-center">
            <p><span>產品名稱 : </span>{{ selectedPro.nameZhHk }}</p>
            <p><span>產品系列 : </span> {{ selectedPro.posId }}</p>
            <p><span>價格 : </span> HKD {{ selectedPro.price }}</p>
          </b-col>
        </b-row>
        <b-form-input v-model="selectedSortOrder" type="number"></b-form-input>
        <p class="mt-2 text-primary" style="font-size: 11px;">*排序編號超過可用產品數量將被調整到最大的可用位置。</p>
        <div class="d-flex mt-4 align-items-center justify-content-end">
          <b-button @click="hideSortingModal()" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">關閉</b-button>
          <b-button :disabled="!selectedSortOrder" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">保存</b-button>
        </div>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import {
  deactivateProduct,
  activateProduct,
  deleteProduct,
  updateSortingOrderForProducts,
  getAllProducts
} from '@/services/product.service'
import {
  getAllProductSeries
} from '@/services/category.service'
import draggable from 'vuedraggable'
import IconSortingOrder from '../../components/icons/IconSortingOrder.vue'
import Pagination from '../../components/Pagination.vue'
import { filterByDate } from '../../utils'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Product',

  data: () => ({
    filters: {
      seriesName: 'all',
      seriesId: null,
      name: '',
      start: '',
      end: '',
      size: 10,
      page: 1
    },
    seriesOptions: [
      {
        value: 'all',
        text: 'All'
      }
    ],
    products: [],
    dragging: false,
    draggingId: null,
    selectedSortOrder: null,
    selectedPro: null,
    isLoading: false,
    draggableData: [],
    categories: [],
    globalSearch: null
  }),
  components: {
    draggable,
    IconSortingOrder,
    Pagination,
    Multiselect
  },
  mounted () {
    this.getAllCategories()
    this.getAll()
  },
  computed: {
    categorizedProduct () {
      return this.products?.filter((item) => item?.seriesId === this.filters.seriesId)
    },
    filterProduct () {
      return this.products?.filter(
        (item) =>
          this.filters.seriesName === 'all'
            ? item
            : item?.seriesId === this.filters.seriesName
      ).filter((item) =>
        this.filters.name.length ? item?.nameZhHk?.toLowerCase()
          ?.replace(/\s+/g, '')
          .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.nameZhCn?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.posId?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, ''))
          : true
      ).filter((item) =>
        filterByDate(
          item.createdDate,
          this.filters.start,
          this.filters.end
        )
      ).filter((item) => this.globalSearch?.nameZhHk?.length
        ? this.globalSearch.nameZhHk === item.nameZhHk && this.globalSearch.id === item.id : true)
    },
    paginatedData () {
      return this.filterProduct.slice(
        (this.filters.page - 1) * this.filters.size,
        this.filters.page * this.filters.size
      )
    },
    disabledDraggable  () {
      return this.filters.name || this.filters.start || this.filters.end || !!this.globalSearch
    }
  },
  watch: {
    filterProduct () {
      this.filters.page = 1
    },
    paginatedData (newData) {
      this.draggableData = [...newData]
    }
  },
  methods: {
    async getAllCategories () {
      this.categories = await getAllProductSeries()
      this.seriesOptions = []
      this.seriesOptions.push(
        ...this.categories.data?.data.content?.map((item) => {
          return {
            value: item?.id,
            text: item?.nameZhHk
          }
        })
      )
      if (this.seriesOptions.length) {
        const categoryId = this.$route.hash.slice(1)
        this.filters.seriesId = Number(categoryId) ? +categoryId : this.seriesOptions[0].value
      }
    },
    async handleRearrangeOrder (isNewProduct = true) {
      let updatedItems
      if (isNewProduct) {
        const product = this.products.find((item) => item.sortingOrder === 0)
        updatedItems = this.products.filter((item) => item.seriesId === product.seriesId)
      } else {
        updatedItems = this.filterProduct
      }
      const mapUpdatedItems = updatedItems.sort((a, b) => a.sortingOrder - b.sortingOrder).map((item, idx) => (
        {
          productId: item.id,
          sortingOrder: idx + 1
        }
      ))
      await updateSortingOrderForProducts(mapUpdatedItems)
      this.getAll()
    },
    async getAll (isLoading = true) {
      this.isLoading = isLoading
      const res = await getAllProducts()
      const categories = await getAllProductSeries()
      this.seriesOptions = []
      this.seriesOptions.push(
        ...categories.data?.data.content?.map((item) => {
          return {
            value: item?.id,
            text: item?.nameZhHk
          }
        })
      )
      this.products = res.data.data?.map((product, index) => {
        const category = categories.data?.data.content?.find((category, index) => category?.id === product?.seriesId)
        return {
          ...product,
          seriesNameHK: category?.nameZhHk || '',
          seriesSortingOrder: category?.sortingOrder
        }
      })

      this.products.sort((item1, item2) => item1?.sortingOrder - item2?.sortingOrder)
      this.products.sort((item1, item2) => item1?.seriesSortingOrder - item2?.seriesSortingOrder)

      // sorting by series name
      // var temp
      // for (var i = 0; i < this.products?.length; i++) {
      //   for (var j = i + 1; j < this.products?.length; j++) {
      //     if (this.products[i].seriesNameHK.localeCompare(this.products[j].seriesNameHK) >= 0) {
      //       temp = this.products[i]
      //       this.products[i] = this.products[j]
      //       this.products[j] = temp
      //     }
      //   }
      // }
      this.isLoading = false
    },
    handleDeactivate (id) {
      deactivateProduct(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品推廣', '停用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleActivate (id) {
      activateProduct(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品推廣', '啟用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleDelete (id, idx) {
      deleteProduct(id).then(
        res => {
          if (res.data.success) {
            this.filterProduct.splice(idx, 1)
            this.handleRearrangeOrder(false)
            this.makeToast('success', '產品推廣', '刪除成功')
          } else {
            this.makeToast('danger', '產品推廣', res.data.message)
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    async handleDragEnd () {
      const start = (this.filters.page - 1) * this.filters.size
      this.filterProduct.splice(start, this.filters.size, ...this.draggableData)
      const sortedData = this.filterProduct.map((item, idx) =>
        ({ productId: item.id, sortingOrder: idx + 1 }))
      await updateSortingOrderForProducts(sortedData)
      await this.getAll(false)
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    showSortingModal (item) {
      this.selectedPro = item
      this.selectedSortOrder = item.sortingOrder
      this.$bvModal.show('setSortingOrder')
    },
    hideSortingModal () {
      this.$bvModal.hide('setSortingOrder')
      this.selectedPro = null
    },
    async submitChangeSorting (currentOrder, newOrder) {
      const items = this.categorizedProduct
      const itemToUpdate = items.find(item => item.id === this.selectedPro.id)
      if (!itemToUpdate) return items

      itemToUpdate.sortingOrder = newOrder

      const updatedItems = items.map(item => {
        if (item.id !== itemToUpdate.id) {
          if (item.sortingOrder > currentOrder && item.sortingOrder <= newOrder) {
            return { productId: item.id, sortingOrder: item.sortingOrder - 1 }
          } else if (item.sortingOrder < currentOrder && item.sortingOrder >= newOrder) {
            return { productId: item.id, sortingOrder: item.sortingOrder + 1 }
          }
          return { productId: item.id, sortingOrder: item.sortingOrder }
        }
        return { productId: itemToUpdate.id, sortingOrder: item.sortingOrder }
      })
      const mapUpdatedItems = updatedItems.sort((a, b) => a.sortingOrder - b.sortingOrder).map((item, idx) => (
        {
          productId: item.productId,
          sortingOrder: idx + 1
        }
      ))
      await updateSortingOrderForProducts(mapUpdatedItems)
      this.getAll()
      this.hideSortingModal()
    },
    onClickSeriesList (item) {
      this.filters = {
        seriesId: item.value,
        name: '',
        start: '',
        end: '',
        size: 10,
        page: 1
      }
      this.filters.seriesName = item.value
      this.globalSearch = null
    },
    nameFormatter (option) {
      return `${option.posId} - ${option.nameZhHk}`
    },
    scrollToSection (sectionId) {
      const section = document.getElementById(sectionId)
      section.scrollIntoView({ behavior: 'smooth' })
    },
    handleValueChange (val) {
      if (!val) return
      const categories = this.categories.data?.data.content
      const category = categories.find((item) => item.id === val.seriesId)
      this.filters.seriesId = category ? category.id : categories[0]
      this.filters = {
        ...this.filters,
        name: '',
        start: '',
        end: '',
        size: 10,
        page: 1
      }
      this.$router.push('/product#' + this.filters.seriesId)
    },
    handleValueRemove () {
      this.globalSearch = null
    }
  }
}
</script>

<style scoped>
.grab {
  cursor: move;
}
.grab-normal {
  cursor: normal !important;
}
.sidebar-fixed {
  position: fixed;
  top: 65px;
  bottom: 0;
  height: calc(100vh - 65px);
  width: 190px;
  background-color: #f8f9fa;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar-title {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: #E6D9D1;
  flex-shrink: 0;
}

.list-group-container {
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
  color:#6B5045;
}

.content-area {
  margin-left: 200px;
  padding: 20px;
  height: calc(100vh - 65px);
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.list-group-container::-webkit-scrollbar {
  display: none;
}

.list-group-item.active {
  background-color: #9F836E;
  border-color: #9F836E;
}
.multiselect__option span{
  white-space: normal !important;
  word-break: break-all;
}

::v-deep .multiselect__content-wrapper {
  box-shadow: #0000001a 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: #E5E5E5 !important;
}
</style>
